import { StyledAvatar } from "@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar";
import { FileTypeToReactDropzoneAccept, fontFamilies } from "@cfra-nextgen-frontend/shared/src/utils";
import { Grid, styled, ThemeProvider, Tooltip, Typography } from "@mui/material";
import upload from '@cfra-nextgen-frontend/shared/src/assets/icons/upload.svg';
import closeIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/close10x10.svg';
import { TypographyStyle17, TypographyStyle19, TypographyStyle7, TypographyStyle15 } from "@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography";
import { useDropzone } from 'react-dropzone';
import { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { DefaultCFRASnack, ETFLinkButton, RoundedTextButton } from "@cfra-nextgen-frontend/shared";
import { processWatchlistCSVFile } from "@cfra-nextgen-frontend/shared/src/components/Form/shared/utils";
import edit from '@cfra-nextgen-frontend/shared/src/assets/icons/edit.svg';
import { AgGridSelectedRowsContextProvider } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext";
import { AgGridCard } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard";
import { ColumnDef } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/types";
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV3.scss';
import React from "react";
import { ProjectSpecificResourcesContext } from "@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context";
import { getCompanyTickerMatcherConfig, TickerMatcherResponse, TickerMatcherData, TickerMatcherSelectField, TickerMatcherResult } from "@cfra-nextgen-frontend/shared/src/utils/company";
import { uniqBy } from "lodash";
import { Tabs } from "@cfra-nextgen-frontend/shared/src/components/Tabs";
import { CustomTabPanel } from "@cfra-nextgen-frontend/shared/src/components/TabPanel/CustomTabPanel";
import { CompanySearchCell } from "components/companySearch";
import search14x14 from '@cfra-nextgen-frontend/shared/src/assets/icons/search14x14.svg';
import { AutoSuggestCompany, Hit } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch";
import { tooltipTheme } from "@cfra-nextgen-frontend/shared/src/components/themes/theme";
import { useSnackbar } from 'notistack';
import { SnackMessageForm } from "@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm";
import { cancelButtonTheme, nextButtonTheme, tabTheme } from "../theme";
import { chunk } from 'lodash';

let selectedColumnsOnStep2: string[] = [];
const chunkSize = 90;
export type FileUploadModalRef = {
    shouldShowExitForm: () => boolean
}

const StyledTypography = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    fontWeight: 400,
    lineHeight: 'inherit',
    color: '#3C3C3C'
}));

function SuccessfulTickerCell(props: ICellRendererParams) {
    return (
        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <div style={{ width: '30px' }}>
                <StyledAvatar src={search14x14} style={{ height: '14px', width: '14px', marginRight: '10px' }} className="success-search-icon" />
            </div>
            <span>{props.value}</span>
        </Grid>
    )
}

function SuccessfulCompanyMatchCell(props: ICellRendererParams) {
    const data: TickerMatcherData = props.data as TickerMatcherData;
    return (
        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} onClick={() => { 
            if (!props.node.rowIndex) {
                return;
            }

            props.api.startEditingCell({
                rowIndex: props.node.rowIndex,
                colKey: 'companyName'
            })
        }}>
            <StyledTypography>{data.result[0].data.displayValue}</StyledTypography>
        </Grid>
    )
}

function FailedCompanyMatchRenderCell(props: ICellRendererParams) {
    return (
        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} onClick={() => {
            if (!props.node.rowIndex) {
                return;
            }
            
            props.api.startEditingCell({
                rowIndex: props.node.rowIndex,
                colKey: 'companyNameFailed'
            })
        }}>
            <div style={{ width: '30px' }}>
                <StyledAvatar src={search14x14} style={{ height: '14px', width: '14px', marginRight: '10px' }} />
            </div>
            <StyledTypography>{'Search for Company'}</StyledTypography>
        </Grid>
    )
}

function RemoveWatchlistData(props: ICellRendererParams | any) {
    const rowIndex = props.node.rowIndex;
    
    return (
        <ThemeProvider theme={tooltipTheme}>
            <Tooltip title={'Remove identifier from the watchlist.'}>
                <StyledAvatar src={closeIcon} sx={{ height: '10px', width: '10px', cursor: 'pointer' }} onClick={() => {
                    props.onRemoveIconClick?.(rowIndex);
                }} />
            </Tooltip>
        </ThemeProvider>
    )
}

const getTickerMatcherResultDataFromSource = (rowData: Hit<AutoSuggestCompany>): TickerMatcherResult[] => {
    return [{
        confidence: 'NA',
        data: {
            "company_security.company.cfra_company_id": rowData._source["company_security.company.cfra_company_id"],
            "company_security.company.company_name": rowData._source["company_security.company.company_name"],
            "company_security.security_trading.cfra_security_trading_id": rowData._source["company_security.security_trading.cfra_security_trading_id"],
            "company_security.security_trading.exchange_lid.exchange_code": rowData._source["company_security.security_trading.exchange_lid.exchange_code"],
            "company_security.security_trading.ticker_symbol": rowData._source["company_security.security_trading.ticker_symbol"],
            displayValue: `${rowData._source["company_security.company.company_name"]} ${rowData._source["company_security.security_trading.ticker_symbol"]} ${rowData._source["company_security.security_trading.exchange_lid.exchange_code"] ? '(' + rowData._source["company_security.security_trading.exchange_lid.exchange_code"] + ')' : ''}`,
            key: rowData._source["company_security.security_trading.cfra_security_trading_id"]
        }
    }]
}

const commonColumnDef: ColumnDef[] = [{
    type: 'leftAligned',
    field: 'identifier',
    sortable: false,
    resizable: false,
    headerName: 'Your Identifier',
    cellStyle: {
        backgroundColor: '#D9D9D933'
    },
    flex: 0.5
}];

const sortResult = (result: TickerMatcherData[], uniqueIdentifiers: any[][]) => {
    const identifiersSortOrder: Record<string, number> = {};
    let index = 0;
    for (const val of uniqueIdentifiers) {
        if (!identifiersSortOrder[val.join('|')]) {
            identifiersSortOrder[val.join('|')] = index;
            index++;
        }
    }
    return result?.sort((a: TickerMatcherData, b: TickerMatcherData) => {
        return (identifiersSortOrder[a.identifier.join('|')] - identifiersSortOrder[b.identifier.join('|')]);
    });
}

function FirstStep({ setWatchlistData }: { setWatchlistData: ({ fileName, data }: { fileName: string, data: any }) => void }) {
    const inputId = `file-input-watchlist`;

    const onDrop = useCallback(
        (acceptedFiles: Array<File>) => {
            const inputElement = document.getElementById(inputId) as HTMLInputElement;
            if (!inputElement || acceptedFiles.length === 0) {
                return;
            }

            // Create a DataTransfer to simulate file drop
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(acceptedFiles[0]);
            // Assign the files to the input element
            inputElement.files = dataTransfer.files;
            // Manually trigger the onChange event
            const event = new Event('change', { bubbles: true });
            inputElement.dispatchEvent(event);
        },
        [inputId],
    );

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const selectedFile = event.target.files[0];

        processWatchlistCSVFile({
            file: selectedFile,
            supportedFileExtensions: ['csv'],
            showValidationError: (message) => { },
            setData: (data) => {
                setWatchlistData({
                    fileName: selectedFile.name,
                    data
                });
            },
        });

        // Reset the file input value to allow the same file to be selected again
        event.target.value = '';
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: FileTypeToReactDropzoneAccept['csv'],
    });

    return (
        <Grid display={'flex'} flexDirection={'column'} sx={{
            height: '270px',
            width: '100%'
        }}>
            <TypographyStyle7 style={{
                fontFamily: fontFamilies.GraphikRegular,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '28px'
            }}>By File Upload</TypographyStyle7>
            <Grid sx={{
                backgroundColor: '#FFF',
                border: '1px dotted #AAAAAA',
                borderRadius: '10px',
                margin: '20px 30px'
            }}
                item sm={12}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                style={isDragActive ? { backgroundColor: '#f3f5fc' } : {}}
                {...{ ...getRootProps(), onClick: () => { } }}>
                <StyledAvatar src={upload} sx={{ height: '32px', width: '32px' }} />
                <Grid item display={'flex'} flexDirection={'row'} style={{ marginTop: '12px' }}>
                    {isDragActive ? <TypographyStyle19>{'Drop here to upload file.'}</TypographyStyle19> : <TypographyStyle19>{'Drag & drop or'}</TypographyStyle19>}
                    <input
                        id={inputId}
                        type='file'
                        accept={`.csv`}
                        {...getInputProps()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                    />
                    {isDragActive ? <></> : <ETFLinkButton
                        onClick={() => document.getElementById(inputId)?.click()}
                        text={'choose file'}
                        sx={{
                            fontFamily: fontFamilies.GraphikRegular,
                            fontSize: '16px',
                            lineHeight: '28px',
                            fontWeight: 500,
                            color: '#007AB9',
                            marginX: '5px'
                        }}
                    />}
                    {isDragActive ? <></> : <TypographyStyle19>{'to upload CSV.'}</TypographyStyle19>}
                </Grid>
            </Grid>
        </Grid>
    )
}

function TopSection({ fileName, onFileReupload, selectedFieldName, onReSelectColumn }: {
    fileName: string | undefined,
    selectedFieldName: string | undefined,
    onFileReupload: ({ fileName, data }: { fileName: string, data: any }) => void,
    onReSelectColumn?: () => void
}) {
    const inputId = `file-input-watchlist`;
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const selectedFile = event.target.files[0];

        processWatchlistCSVFile({
            file: selectedFile,
            supportedFileExtensions: ['csv'],
            showValidationError: (message) => { },
            setData: (data) => {
                onFileReupload({
                    fileName: selectedFile.name,
                    data
                });
            },
        });

        // Reset the file input value to allow the same file to be selected again
        event.target.value = '';
    };

    return (
        <Grid item sm={12} display={'flex'} paddingBottom={'10px'} borderBottom={'1px dotted #CCCCCC'}>
            <Grid item sm={5} display={'flex'} flexDirection={'column'}>
                <Grid display={'flex'}>
                    <TypographyStyle15 style={{ color: '#5A5A5A' }}>Selected File</TypographyStyle15>
                    <StyledAvatar src={edit} style={{ marginLeft: 15, height: '10px', width: '10px', marginTop: '5px' }} />
                    <input
                        id={inputId}
                        type='file'
                        accept={`.csv`}
                        hidden
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                    />
                    <ETFLinkButton
                        onClick={() => document.getElementById(inputId)?.click()}
                        text={'Edit'}
                        sx={{
                            fontFamily: fontFamilies.GraphikRegular,
                            fontSize: '12px',
                            fontWeight: 400,
                            minWidth: '40px',
                            color: '#007AB9'
                        }}
                    />
                </Grid>
                <Grid item>
                    <TypographyStyle7 style={{ fontWeight: 400, lineHeight: '25px' }}>{fileName}</TypographyStyle7>
                </Grid>
            </Grid>
            {selectedFieldName && <>
                <Grid item sm={0.2} display={'flex'} flexDirection={'column'} padding={'5px'} width={'20px'}>
                    <Grid borderLeft={'1px dotted #CCCCCC'} height={'100%'} />
                </Grid>
                <Grid item sm={5} display={'flex'} flexDirection={'column'}>
                    <Grid display={'flex'}>
                        <TypographyStyle15 style={{ color: '#5A5A5A' }}>Selected Column</TypographyStyle15>
                        <StyledAvatar src={edit} style={{ marginLeft: 15, height: '10px', width: '10px', marginTop: '5px' }} />
                        <ETFLinkButton
                            onClick={onReSelectColumn}
                            text={'Edit'}
                            sx={{
                                fontFamily: fontFamilies.GraphikRegular,
                                fontSize: '12px',
                                fontWeight: 400,
                                minWidth: '40px',
                                color: '#007AB9'
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TypographyStyle7 style={{ fontWeight: 400, lineHeight: '25px' }}>{selectedFieldName}</TypographyStyle7>
                    </Grid>
                </Grid></>}
        </Grid>
    )
}

const SecondStep = ({ watchlistRecords, fileName, onCancelClick, onFileReupload, onStep2NextClick, field }: {
    watchlistRecords: Array<any> | undefined,
    fileName: string | undefined,
    onCancelClick: (showExitForm: boolean) => void,
    onFileReupload: ({ fileName, data }: { fileName: string, data: any }) => void
    onStep2NextClick: ({ selectedField, identifiers }: { selectedField: string[], identifiers: string[][] | undefined }) => void
    field?: string[] | undefined
}) => {
    const gridRef = useRef<AgGridReact>(null);
    const [selectedField, setSelectedField] = useState<string[]>();

    useEffect(() => {
        const newColumns = columnDefs.map((existingCol, index) => {    
            return {
                ...existingCol,
                cellStyle: selectedColumnsOnStep2.includes(existingCol.field || '') ? {
                    backgroundColor: '#F3F5FC'
                } : { backgroundColor: '#FFF' },
                headerClass: selectedColumnsOnStep2.includes(existingCol.field || '') ? 'highlight-header' : ''
            }
        });
        setColumnDefs(newColumns);
        setEnableNext(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedField])

    const onHeaderClick = useCallback((e: any, field: string) => {
        selectedColumnsOnStep2 = selectedColumnsOnStep2?.includes(field) ? 
            selectedColumnsOnStep2?.filter(f => f !== field) : [...(selectedColumnsOnStep2 || []), field];
        setSelectedField([field]);
    }, []);

    function CustomHeader({ field }: any) {
        return (
            <div onClick={(e) => onHeaderClick(e, field) } style={{ width: '100%', cursor: 'pointer' }}>{field?.replaceAll('_', ' ')}</div>
        );
    }

    const [columnDefs, setColumnDefs] = useState<ColumnDef[]>(Object.keys(watchlistRecords?.[0]).map(p => ({
        type: 'leftAligned',
        field: p,
        sortable: false,
        resizable: false,
        headerComponent: CustomHeader,
        headerComponentParams: {
            field: p
        },
        cellStyle: field?.includes(p) ? {
            backgroundColor: '#F3F5FC'
        } : { backgroundColor: '#FFF' },
        headerClass: field?.includes(p) ? 'highlight-header' : ''
    })));
    const [enableNext, setEnableNext] = useState(false);

 
    useEffect(() => {
        if (field && field?.length > 0) {
            setEnableNext(true);
            setSelectedField(field);
        }
    }, [field])

    if (watchlistRecords?.length === 0) return <div>No Data</div>

    const onNextClick = () => {
        if (selectedColumnsOnStep2) {
            onStep2NextClick({ selectedField: selectedColumnsOnStep2, 
                identifiers: watchlistRecords?.map((record) => 
                    selectedColumnsOnStep2.map(sr => String(record[sr])))
            });
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
            <Grid sx={{
                overflow: 'hidden',
                flexGrow: 1,
                width: '100%',
                paddingTop: '20px',
            }}>
                <TopSection fileName={fileName} onFileReupload={onFileReupload} selectedFieldName={undefined} />
                <Grid item sm={12} display={'flex'} paddingY={'10px'} flexDirection={'column'}>
                    <TypographyStyle15 style={{ color: '#5A5A5A' }}>Column</TypographyStyle15>
                    <TypographyStyle17 style={{ color: '#3C3C3C', fontSize: '12px', fontWeight: 400 }}>{'Select the column containing the security identifier(s).'}</TypographyStyle17>
                </Grid>
                <Grid paddingTop={'10px'}>
                    <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                        <AgGridCard
                            ref={gridRef}
                            showDefaultExportButton={false}
                            columnDefs={columnDefs}
                            getResizableMinWidthForColumn={() => 150}
                            labelPanelContainerStyles={{ paddingTop: '36px' }}
                            rowsData={watchlistRecords}
                            gridTheme='grid-theme-v3'
                            fullHeightGrid={true}
                            maxNumberOfRowsToDisplay={7}
                        />
                    </AgGridSelectedRowsContextProvider>
                </Grid>
            </Grid>
            <Grid sx={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', paddingTop: '20px' }}>
                <RoundedTextButton buttonText="Cancel" theme={cancelButtonTheme} onClickCallback={() => onCancelClick(true)} />
                <RoundedTextButton buttonText="Next" theme={nextButtonTheme} sx={{ marginLeft: '10px' }} disabled={!enableNext} onClickCallback={onNextClick} />
            </Grid>
        </div>
    )
}

function ThirdStep({ selectedField, identifiers, onCancelClick, onFileReupload, fileName, onReSelectColumn, setCreateWatchlistData }: {
    selectedField: string[] | undefined,
    identifiers: any[] | undefined,
    onCancelClick: (showExitForm: boolean) => void
    fileName: string | undefined,
    onFileReupload: ({ fileName, data }: { fileName: string, data: any }) => void,
    onReSelectColumn?: () => void,
    setCreateWatchlistData: (data: { cfra_security_trading_id: string; cfra_company_id: string; }[]) => void;
}) {
    const gridRefSuccess = useRef<AgGridReact>(null);
    const gridRefFailed = useRef<AgGridReact>(null);
    const [successfulMatches, setSuccessfulMatches] = useState<TickerMatcherData[] | undefined>();
    const [failedMatches, setFailedMatches] = useState<TickerMatcherData[] | undefined>();
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const { sendMultipleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendMultipleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const uniqueIdentifiers = useMemo(() => {
        if (identifiers) {
            return uniqBy(identifiers, (val: string[]) => val.join('|').toLocaleLowerCase());
        }
        return [];
    }, [
        identifiers
    ])

    const chunks = chunk(uniqueIdentifiers, chunkSize);

    const getTickerMatcherDataQry = sendMultipleRequest<{ data: TickerMatcherResponse }[]>(chunks.map((c) => {
        return {
            searchByParams: {
                requestBody: {
                    "identifiers": c
                },
                noErrorOnNoKeyValuePairs: true,
                config: { enabled: (c?.length && c?.length > 0) ? true : false },
            },
            config: getCompanyTickerMatcherConfig
        }
    })
    );

    const isBulkEntityOperationCompleted = useMemo(
        () =>
            getTickerMatcherDataQry &&
            getTickerMatcherDataQry.length > 0 &&
            getTickerMatcherDataQry.every((result) => result && !result.isLoading),
        [getTickerMatcherDataQry],
    );

    useEffect(() => {
        if (!isBulkEntityOperationCompleted) {
            return;
        }
        if (identifiers && !successfulMatches) {
            let allMatches: TickerMatcherData[] = [];
            let allFailedMatches: TickerMatcherData[] = [];
            getTickerMatcherDataQry.forEach((data) => {

                const matched_result: TickerMatcherData[] = data?.data?.data?.matched_result;
                matched_result.forEach((r) => {
                    r.result = r.result.sort((a, b) => {
                        return Number(b.confidence) - Number(a.confidence);
                    })
                    r.result.forEach((r, index) => {
                        let value = `${r.data["company_security.company.company_name"]}`;

                        if (index > 0)
                            value = `${r.data["company_security.company.company_name"]} ${r.data["company_security.security_trading.ticker_symbol"]} ${r.data["company_security.security_trading.exchange_lid.exchange_code"] ? '(' + r.data["company_security.security_trading.exchange_lid.exchange_code"] + ')' : ''}`;

                        r.data = {
                            ...r.data,
                            key: r.data["company_security.security_trading.cfra_security_trading_id"] || r.data["company_security.company.cfra_company_id"],
                            displayValue: value
                        }
                    })
                });
                allMatches = allMatches.concat(matched_result);
                allFailedMatches = allFailedMatches.concat(data?.data?.data?.failed_matched_result);
            });

            const sortedSuccessfulResult = sortResult(allMatches, uniqueIdentifiers);
            setSuccessfulMatches(sortedSuccessfulResult);
            setFailedMatches(sortResult(allFailedMatches, uniqueIdentifiers));
        }
    }, [
        getTickerMatcherDataQry,
        isBulkEntityOperationCompleted,
        identifiers,
        uniqueIdentifiers,
        successfulMatches
    ])


    const addToFinalWatchlist = (identifier: string[], newResult: TickerMatcherResult[]) => {
        let rowData: TickerMatcherData[] = [];
        gridRefSuccess.current?.api.forEachNode(node => rowData.push(node.data));

        setSuccessfulMatches([...(rowData || []), {
            identifier,
            result: newResult
        }]);
    }

    const onCreateWatchlist = useCallback(() => {
        let rowData: TickerMatcherData[] = [];
        gridRefSuccess.current?.api.forEachNode(node => rowData.push(node.data));

        if (rowData.length > 0) {
            setCreateWatchlistData(rowData.map((data) => ({
                "cfra_security_trading_id": data.result[0].data["company_security.security_trading.cfra_security_trading_id"]?.toString(),
                "cfra_company_id": data.result[0].data["company_security.company.cfra_company_id"]?.toString()
            })));
        }
        else {
            ShowSnack(SnackMessageForm({
                message: 'No data to create watchlist'
            }))
        }
    }, [ShowSnack, setCreateWatchlistData]);

    if (!identifiers?.length) {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TopSection fileName={fileName} onFileReupload={onFileReupload} selectedFieldName={undefined} />
                <h3 style={{ color: '#5A5A5A' }}>No Identifiers</h3>
            </div>
        );
    }

    const columnDefsSuccess: ColumnDef[] = [...commonColumnDef, {
        type: 'leftAligned',
        sortable: false,
        resizable: false,
        headerName: 'Ticker',
        headerClass: 'ticker-header',
        valueGetter: p => p.data.result[0].data[TickerMatcherSelectField.ticker_symbol],
        cellStyle: {
            fontWeight: '500',
            fontFamily: fontFamilies.GraphikMedium,
            borderRight: 'none'
        },
        cellRenderer: SuccessfulTickerCell,
    }, {
        colId: 'companyName',
        type: 'leftAligned',
        sortable: false,
        resizable: false,
        headerName: 'Company Name Match',
        flex: 1,
        cellStyle: {
            borderRight: 'none',
            cursor: 'pointer'
        },
        cellRenderer: SuccessfulCompanyMatchCell,
        editable: true,
        cellEditor: CompanySearchCell,
        cellEditorParams: {
            showDefaultResultOptions: true,
            onDefaultOptionClick: (rowIndex: string, res: TickerMatcherResult) => {
                const rowNode = gridRefSuccess?.current?.api?.getRowNode(rowIndex);

                let matchedIndex = rowNode?.data.result.findIndex((p: TickerMatcherResult) => p.data["company_security.security_trading.cfra_security_trading_id"] === res.data["company_security.security_trading.cfra_security_trading_id"]);
                if (matchedIndex === -1) {
                    matchedIndex = rowNode?.data.result.findIndex((p: TickerMatcherResult) => p.data["company_security.company.cfra_company_id"] === res.data["company_security.company.cfra_company_id"]);
                }
                const newSortedResult = [rowNode?.data.result[matchedIndex], ...rowNode?.data.result.filter((val: any, index: number) => index !== matchedIndex)]
                const newData = { ...rowNode?.data, result: newSortedResult };
                rowNode?.updateData(newData);
                gridRefSuccess?.current?.api?.stopEditing(true);
            },
            onResultOptionClick: (rowIndex: string, rowData: Hit<AutoSuggestCompany>) => {
                const rowNode = gridRefSuccess?.current?.api?.getRowNode(rowIndex);
                const newResult = getTickerMatcherResultDataFromSource(rowData);
                const newData: TickerMatcherData = {
                    identifier: rowNode?.data.identifier,
                    result: newResult
                }
                rowNode?.updateData(newData);
                gridRefSuccess?.current?.api?.stopEditing(true);
            },
            onClickOutsideCallback: () => {
                gridRefSuccess?.current?.api?.stopEditing(true);
            }
        }
    }, {
        type: 'leftAligned',
        field: `result.0.confidence`,
        sortable: false,
        resizable: false,
        headerName: 'Confidence',
        cellStyle: {
            borderRight: 'none',
            fontWeight: '500',
            fontFamily: fontFamilies.GraphikMedium
        },
        valueFormatter: p => p.value && p.value !== 'NA' ? `${(p.value / 100)}%` : p.value
    }, {
        colId: 'removeCompany',
        type: 'leftAligned',
        sortable: false,
        resizable: false,
        headerName: '',
        cellRenderer: RemoveWatchlistData,
        cellRendererParams: {
            onRemoveIconClick: (rowIndex: string) => {
                const rowNode = gridRefSuccess?.current?.api?.getRowNode(rowIndex);
                const identifier = rowNode?.data.identifier;
                if (identifier) {
                    setSuccessfulMatches(successfulMatches?.filter((match) => match.identifier !== identifier));
                }
            }
        },
        cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        }
    }]

    const columnDefsFailed: ColumnDef[] = [...commonColumnDef, {
        colId: 'companyNameFailed',
        type: 'leftAligned',
        sortable: false,
        resizable: false,
        headerName: 'Ticker',
        flex: 1,
        cellRenderer: FailedCompanyMatchRenderCell,
        cellEditor: CompanySearchCell,
        editable: true,
        cellEditorParams: {
            onResultOptionClick: (rowIndex: string, rowData: Hit<AutoSuggestCompany>) => {
                const rowNode = gridRefFailed?.current?.api?.getRowNode(rowIndex);
                const newResult = getTickerMatcherResultDataFromSource(rowData);
                setFailedMatches(failedMatches?.filter((match) => match.identifier.join('|') !== rowNode?.data.identifier.join('|')));
                addToFinalWatchlist(rowNode?.data.identifier, newResult);
                ShowSnack(SnackMessageForm({
                    message: rowData._source["company_security.security_trading.ticker_symbol"] + ' was added to your list'
                }))
            },
            onClickOutsideCallback: () => gridRefFailed?.current?.api?.stopEditing(true)
        }
    }];

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
            <Grid sx={{
                overflow: 'hidden',
                flexGrow: 1,
                width: '100%',
                paddingTop: '20px',
            }}>
                <TopSection fileName={fileName} onFileReupload={onFileReupload} selectedFieldName={selectedField?.join(',')} onReSelectColumn={onReSelectColumn} />
                <Grid paddingTop={'20px'}>
                    <Tabs
                        tabItems={[`Successful Matches: ${successfulMatches?.length || 0}`, `Failed Matches: ${failedMatches?.length || 0}`]}
                        activeTab={currentActiveTab}
                        onChange={(event, val) => {
                            setCurrentActiveTab(val)
                        }}
                        ariaLabel=""
                        theme={tabTheme}
                        tabIndicatorProps={{}}
                    />
                    <CustomTabPanel
                        index={0}
                        value={currentActiveTab}>
                        {!isBulkEntityOperationCompleted ? <h4 style={{ color: '#5A5A5A' }}>Fetching Information...{chunks?.length > 1 && 'This could take a while'}</h4> :
                            <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                                <AgGridCard
                                    ref={gridRefSuccess}
                                    showDefaultExportButton={false}
                                    columnDefs={columnDefsSuccess}
                                    getResizableMinWidthForColumn={() => 150}
                                    labelPanelContainerStyles={{ paddingTop: '36px' }}
                                    rowsData={successfulMatches}
                                    gridTheme='grid-theme-v3'
                                    fullHeightGrid={true}
                                    customFlexibleColumns={['identifier']}
                                    maxNumberOfRowsToDisplay={7}
                                />
                            </AgGridSelectedRowsContextProvider>
                        }
                    </CustomTabPanel>
                    <CustomTabPanel
                        index={1}
                        value={currentActiveTab}>
                        <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                            <AgGridCard
                                ref={gridRefFailed}
                                showDefaultExportButton={false}
                                columnDefs={columnDefsFailed}
                                getResizableMinWidthForColumn={() => 150}
                                labelPanelContainerStyles={{ paddingTop: '36px' }}
                                rowsData={failedMatches}
                                gridTheme='grid-theme-v3'
                                fullHeightGrid={true}
                                customFlexibleColumns={['identifier']}
                                maxNumberOfRowsToDisplay={7}
                            />
                        </AgGridSelectedRowsContextProvider>
                    </CustomTabPanel>
                </Grid>
            </Grid>
            <Grid sx={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', paddingTop: '20px' }}>
                <RoundedTextButton buttonText="Cancel" theme={cancelButtonTheme} onClickCallback={() => onCancelClick(true)} />
                <RoundedTextButton buttonText="Create" theme={nextButtonTheme} sx={{ marginLeft: '10px' }} disabled={successfulMatches?.length === 0} onClickCallback={() => onCreateWatchlist()} />
            </Grid>
        </div>
    )
}

export const NewWatchlistFileUploadModal = React.forwardRef<FileUploadModalRef, { handleCloseModal: (showExitForm: boolean) => void, setCreateWatchlistData: (data: { cfra_security_trading_id: string; cfra_company_id: string; }[]) => void }>(({ handleCloseModal, setCreateWatchlistData }, ref) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [watchlistRecords, setWatchlistRecords] = useState<Array<any>>();
    const [selectedFileName, setSelectedFileName] = useState<string>();
    const [counter, setCounter] = useState<number>(0);
    const [identifiers, setIdentifiers] = useState<any[] | undefined>();
    const [selectedField, setSelectedField] = useState<string[]>();

    useEffect(() => {
        return () => {
            // reset selectedColumnsOnStep2 when modal is unmounted
            selectedColumnsOnStep2 = [];
        }
    }, []);


    const shouldShowExitForm = () => {
        return currentStep < 1 ? false : true;
    }

    useImperativeHandle(ref, () => ({
        shouldShowExitForm
    }));


    const setWatchlistData = ({ fileName, data }: { fileName: string, data: any }) => {
        if (data?.length > 0) {
            setWatchlistRecords(data);
            setSelectedFileName(fileName);
            setCounter(counter + 1);
            setCurrentStep(1);
        }
    }

    const onStep2NextClick = ({ selectedField, identifiers }: { selectedField: string[], identifiers: any[] | undefined }) => {
        setIdentifiers(identifiers);
        setSelectedField(selectedField);
        setCurrentStep(2);
    }

    const onCancelClick = (showExitForm: boolean) => {
        handleCloseModal(showExitForm);
    }

    const getFileUploadUI = (currentStep: number) => {
        switch (currentStep) {
            case 0:
                return <FirstStep setWatchlistData={setWatchlistData} />
            case 1:
                return <SecondStep watchlistRecords={watchlistRecords} fileName={selectedFileName} onCancelClick={onCancelClick}
                    onFileReupload={setWatchlistData} onStep2NextClick={onStep2NextClick} field={selectedField} key={selectedFileName + counter.toString()} />
            case 2:
                return <ThirdStep identifiers={identifiers} selectedField={selectedField} onCancelClick={onCancelClick}
                    onFileReupload={setWatchlistData} fileName={selectedFileName}
                    onReSelectColumn={() => setWatchlistData({ fileName: selectedFileName || '', data: watchlistRecords })}
                    setCreateWatchlistData={setCreateWatchlistData} />
            default:
                return <FirstStep setWatchlistData={setWatchlistData} />
        }
    }

    return (
        getFileUploadUI(currentStep)
    )
})