import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';

export enum TickerMatcherSelectField {
    cfra_company_id = "company_security.company.cfra_company_id",
    company_name =  "company_security.company.company_name",
    cfra_security_trading_id = "company_security.security_trading.cfra_security_trading_id",
    exchange_code = "company_security.security_trading.exchange_lid.exchange_code",
    ticker_symbol = "company_security.security_trading.ticker_symbol",
    displayValue = 'displayValue',
    key = 'key'
}

export type TickerMatcherResult = {
    confidence: number | 'NA',
    data: Record<TickerMatcherSelectField, string | number>
}

export type TickerMatcherData = {
    identifier: string[],
    result: TickerMatcherResult[]
}

export type TickerMatcherResponse = {
    matched_result: TickerMatcherData[],
    failed_matched_result: TickerMatcherData[]
}

export const getCompanyTickerMatcherConfig: Parameters<SendSingleRequest>[1] = {
    path: 'company/ticker-matcher?match_mode=both',
    apiName: ApiNames.Research,
    queryKeyFirstElement: 'getCompanyTickerMatcherConfig',
    requestType: RequestTypes.POST
};